import * as React from "react";
import styled from "styled-components";
import Column from "./Column";
// import AssetRow from "./AssetRow";
import Icon from "./Icon";
import Button from "../components/Button";
import { fonts } from "../styles";
import { IAssetData } from "../helpers/types";
import { apiGetMetadata } from "../helpers/api";
import nfticon from "../assets/nft.png";
import { TOKENARY } from "../../../dist/providers/injected";
import Web3 from "web3";
import { callRedeem } from "../helpers/web3";

const SAssetRow = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
const SAssetRowLeft = styled.div`
  display: flex;
  align-items: center;
`;
const SAssetName = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  text-align: left;

  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 300px;

`;
const SAssetRowRight = styled.div`
  display: flex;
`;
const SAssetBalance = styled.div`
  font-size: 10px;
  align-items: center;
  width: 100%;
  display: flex;
  text-align:left;
`;

const STestButton = styled(Button)`
  border-radius: 8px;
  font-size: ${fonts.size.small};
  height: 34px;
  width: 100%;
  max-width: 175px;
  margin: 12px;
`;



const AccountNFTs = (props: any) => {


  // const redeemClick = async (tokenId: string) => { //redeemClick=()=>{
  //   props.redeemClick(tokenId);
  // }


  const { assets } = props;
  let tokens: IAssetData[] = assets;

  // const tokens: IAssetData[] = assets.filter(async (asset: IAssetData) => {
  //   let metadata

  //   // if(asset.metadata){
  //   //   metadata = JSON.parse(asset.metadata)
  //   //   console.log("asset.metadata", metadata)
  //   //   asset.name = metadata.name
  //   //   asset.image = metadata.image
  //   // }

  //   metadata = await apiGetMetadata(String(asset.token_address), String(asset.token_id))
  //   console.log("metadata", metadata)
  //   asset.name = metadata.name
  //   asset.image = metadata.image_original_url

  //   if(metadata.name == "REVV x Undone Watch Redemption Token") {
  //     asset.redeemAble = true
  //     console.log("yes!!")
  //   }

  // });


  return (
    <Column center>
      {tokens.map((token, index) => (
        // <AssetRow key={token.symbol} asset={token} />
        <SAssetRow key={index} style={{ display : token.redeemAble ? 'block' : 'none'}}>
          <SAssetRowLeft>
            <a href={token.image ? token.image : ''} target={ token.image ? "_blank" : ""}><Icon src={token.image ? token.image : nfticon} /></a>
            <SAssetName>{`${token.name}`} </SAssetName>
          </SAssetRowLeft>
          <SAssetRowRight>
            <SAssetBalance>
              {/* {`${token.amount} ${token.symbol}`} */}
              {`# ${token.token_id}`}
            </SAssetBalance>
            <STestButton onClick={() => props.redeemClick(String(token.token_id))}>
               Redeem
            </STestButton>
          </SAssetRowRight>
        </SAssetRow>




      ))}
    </Column>
  );
};

export default AccountNFTs;
