import axios, { AxiosInstance } from 'axios'
import { IAssetData, IGasPrices, IParsedTx, OpenSeaMetaData } from './types'

const api: AxiosInstance = axios.create({
  baseURL: 'https://ethereum-api.xyz',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})


const moralisApi: AxiosInstance = axios.create({
  baseURL: 'https://deep-index.moralis.io/api/v2',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-API-Key': 'vRVQ24FwWZtv0LHS39TdRjcOQxcJepsrHnkCD8AtSUJdEdWB5B5Y0BD5xxd7yuPV' // TODO: key env
  }
})


export async function apiGetMetadata(
  address: string,
  token_id: string,
): Promise<OpenSeaMetaData> {
  //https://api.opensea.io/api/v1/asset/0xa7a05e655cbed5356d2fa851e96f7f68e4a6f954/57896058422150794506789157388431033163767141676509399538015095964082588614656
  const response = await axios.get(`https://api.opensea.io/api/v1/asset/${address}/${token_id}`)
  // const { result } = response.data
  return response.data
}


export async function apiGetAccountNFTs(
  address: string,
  token_address: string,
  chainId: number
): Promise<IAssetData[]> {
  const response = await moralisApi.get(
    // `/0x31c7ff747b210851c4f85043d7fcbd26ffa01af8/nft/0xa7a05e655cbed5356d2fa851e96f7f68e4a6f954?chain=eth&format=decimal`
    `/${address}/nft/${token_address}?chain=0x${chainId.toString(16)}&format=decimal`
  )
  const { result } = response.data
  return result
}



export async function apiGetAccountAssets(
  address: string,
  chainId: number
): Promise<IAssetData[]> {
  const response = await api.get(
    `/account-assets?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export async function apiGetAccountTransactions(
  address: string,
  chainId: number
): Promise<IParsedTx[]> {
  const response = await api.get(
    `/account-transactions?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetAccountNonce = async (
  address: string,
  chainId: number
): Promise<string> => {
  const response = await api.get(
    `/account-nonce?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetGasPrices = async (): Promise<IGasPrices> => {
  const response = await api.get(`/gas-prices`)
  const { result } = response.data
  return result
}
